import axios, {AxiosResponse} from "axios";
import {appyShopConfig} from "../config";
import {config} from "../config-merchant";
import {LoginDTO} from "../interfaces/customer-interface";
import jwt_decode from "jwt-decode";

export function login(loginBody: LoginDTO) {
    return axios.post(appyShopConfig.apiUrl + config.merchantId + '/customer/login', loginBody)
}

export function isJwtValid(jwt: string): boolean {
    const decoded: any = jwt_decode(jwt);
    return decoded.exp > (new Date().getTime() / 1000);
}

export function getOrderList(jwt: string): Promise<AxiosResponse> {
    return axios.get(appyShopConfig.apiUrl + config.merchantId + '/customer/orders', {
        headers: {Authorization: `Bearer ${jwt}`},
    })
}

export function createAccount(body: {}): Promise<AxiosResponse> {
    return axios.put(appyShopConfig.apiUrl + config.merchantId + '/customer/create', body)
}

export function updateAccount(body: {}, jwt: string): Promise<AxiosResponse> {
    return axios.post(appyShopConfig.apiUrl + config.merchantId + '/customer/update', body, {
        headers: {Authorization: `Bearer ${jwt}`},
    })
}

export function forgotPassword(body: {}, language: string): Promise<AxiosResponse> {
    return axios.put(appyShopConfig.apiUrl + config.merchantId + '/customer/forgot-password/' + language, body)
}

export function resetPassword(body: {}): Promise<AxiosResponse> {
    return axios.post(appyShopConfig.apiUrl + config.merchantId + '/customer/reset-password', body)
}
